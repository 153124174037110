import React from "react";
import info from "../info";

function Currency({ token, chosenPayment }) {
  return (
    <div
      className="currency-borders mt-1"
      style={{
        backgroundColor: token == chosenPayment && info.colors.second,
      }}
    >
      <div className="row">
        <div
          className="col-12"
          style={{
            color: token == chosenPayment ? "white" : info.colors.second,
          }}
        >
          <span style={{ marginRight: "3px" }}>
            <img
              src={token.image}
              alt=""
              style={{ borderRadius: "50%", width: "25px" }}
            />
          </span>
          {token.symbol}
        </div>
      </div>
    </div>
  );
}

export default Currency;

import React from "react";

function Bread({ title }) {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundImage: "url('/banner.webp')",
        height: "30vh", // Yükseklik belirtin
        backgroundSize: "cover", // Resmi kapsayacak şekilde boyutlandırın
        backgroundPosition: "center", // Ortalayın
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h3 style={{ color: "white", fontWeight: 900 }}>{title}</h3>
    </div>
  );
}

export default Bread;

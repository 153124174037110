import axios from "axios";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import constants from "../constants";
import { useGlobalState } from "../hooks/useGlobalState";
import Loading from "./Loading";

function Header({ content }) {
  const { globalState, setGlobalState, logout } = useGlobalState();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Navbar
      expand="lg"
      style={{ padding: "20px" }}
      className="px-5"
      data-bs-theme="light"
    >
      <Container fluid>
        <Navbar.Brand>
          <Link to="/">
            <img src="/logo-header.svg" style={{ width: "180px" }} alt="" />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="navbarScroll"
          className="justify-content-end"
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
            {content &&
              content.menuLinks.map((e, index) => (
                <Nav.Link
                  href={e.link}
                  target={e.target && e.target}
                  key={index}
                >
                  <h5 className="header-links"> {e.title.toUpperCase()}</h5>
                </Nav.Link>
              ))}
            {globalState && globalState.authToken && (
              <Nav.Link href={"#"}>
                <div onClick={handleLogout}>
                  <h5 className="header-links"> {"LOGOUT"}</h5>
                </div>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

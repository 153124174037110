import React from "react";
import info from "../info";

function NormalInput({ value, setValue, placeholder, type, error }) {
  return (
    <div className="token-input">
      <div className="row">
        <div className="col-12">
          <input
            type={type || "text"}
            className="token-input-input"
            value={value}
            placeholder={placeholder && placeholder}
            onChange={(e) => setValue(e.target.value)}
          />
          <label htmlFor="" style={{ color: "red" }}>
            {error && error}
          </label>
        </div>
      </div>
    </div>
  );
}

export default NormalInput;

import { readContract, getBalance, erc721ABI } from "@wagmi/core";
import BigNumber from "bignumber.js";
import { erc20Abi } from "viem";
import { presaleAbi } from "../abi/presaleAbi";
import { config } from "../App";
import info from "../info";

// Token balance'ına ondalık eklemek için
export function removeDecimal(balance, decimal, fixed) {
  if (decimal > 0) {
    const balanceBig = new BigNumber(String(balance));
    const decimalBig = new BigNumber(String(10)).exponentiatedBy(
      String(decimal)
    );
    const result = new BigNumber(balanceBig.dividedBy(decimalBig));
    return result.toFixed(fixed);
  } else {
    const balanceBig = new BigNumber(String(balance));
    return balanceBig.toFixed(fixed);
  }
}

// Token balance'ından ondalık çıkarmak için
export function addDecimal(balance, decimal, fixed) {
  if (decimal > 0) {
    const balanceBig = new BigNumber(String(balance));
    const decimalBig = new BigNumber(String(10)).exponentiatedBy(
      String(decimal)
    );
    const result = new BigNumber(balanceBig.multipliedBy(decimalBig));
    return result.toFixed(fixed);
  } else {
    const balanceBig = new BigNumber(String(balance));
    return balanceBig.toFixed(fixed);
  }
}

export const getTokenBalance = async (walletAddress, tokenAddress) => {
  try {
    const data = await readContract(config, {
      address: tokenAddress,
      abi: erc20Abi,
      functionName: "balanceOf",
      args: [walletAddress],
    });
    return data;
  } catch (error) {
    console.log(error, "fetch balance error");
    return null;
  }
};

export const fetchBalance = async (walletAddress) => {
  try {
    const balance = await getBalance({
      address: walletAddress,
    });
    return balance;
  } catch (error) {
    return null;
  }
};

export const getWeiRaised = async () => {
  try {
    const data = await readContract(config, {
      address: info.contractAddress,
      abi: presaleAbi,
      functionName: "weiRaised",
    });
    return removeDecimal(data.toString(), info.tokenDecimal, 2);
  } catch (error) {
    return 0;
  }
};

export const getHardcap = async () => {
  try {
    const data = await readContract(config, {
      address: info.contractAddress,
      abi: presaleAbi,
      functionName: "getHardCap",
    });
    return removeDecimal(data.toString(), info.tokenDecimal);
  } catch (error) {
    return 0;
  }
};

export const getTokensSold = async () => {
  try {
    const data = await readContract(config, {
      address: info.contractAddress,
      abi: presaleAbi,
      functionName: "tokensSoldInThisRound",
    });
    return removeDecimal(data.toString(), info.tokenDecimal);
  } catch (error) {
    return 0;
  }
};

export const getRefCount = async (address) => {
  try {
    const data = await readContract(config, {
      address: info.contractAddress,
      abi: presaleAbi,
      functionName: "getRefCount",
      args: [address],
    });
    return data.toString();
  } catch (error) {
    return 0;
  }
};

export const getRefsBougth = async (address) => {
  try {
    const data = await readContract(config, {
      address: info.contractAddress,
      abi: presaleAbi,
      functionName: "getRefsBought",
      args: [address],
    });
    return removeDecimal(data.toString(), info.tokenDecimal, 2);
  } catch (error) {
    return 0;
  }
};

export const getUsersBought = async (address) => {
  try {
    const data = await readContract(config, {
      address: info.contractAddress,
      abi: presaleAbi,
      functionName: "userInfo",
      args: [address],
    });
    return removeDecimal(data.toString(), info.tokenDecimal, 2);
  } catch (error) {
    return 0;
  }
};

export const getAllowance = async (address, tokenAddress) => {
  try {
    const data = await readContract(config, {
      address: tokenAddress,
      abi: erc20Abi,
      functionName: "allowance",
      args: [address, info.contractAddress],
    });
    console.log(data);
    return Number(removeDecimal(data.toString(), info.tokenDecimal));
  } catch (error) {
    console.log(error);

    return 0;
  }
};

export const getRate = async () => {
  try {
    const data = await readContract(config, {
      address: info.contractAddress,
      abi: presaleAbi,
      functionName: "getRate",
    });
    console.log(data);
    return data.toString();
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getTokenAmount = async (address, amount) => {
  try {
    const data = await readContract(config, {
      address: info.contractAddress,
      abi: presaleAbi,
      functionName: "getTokenAmountFrom",
      args: [address, amount],
    });
    console.log(data);
    return removeDecimal(data.toString(), info.tokenDecimal, 2);
  } catch (error) {
    console.log(error);
    return 0;
  }
};

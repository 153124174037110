import usdt from "./assets/usdt.png";
import bnb from "./assets/bnb.png";
import tokenImage from "./assets/asset1.png";
import usdc from "./assets/usdc.webp";
import eth from "./assets/eth.webp";
import shiba from "./assets/shiba.webp";
import wbtc from "./assets/wbtc.webp";
import cardano from "./assets/cardano.webp";
import arb from "./assets/arb.webp";
import link from "./assets/link.webp";

const info = {
  contractAddress: "0x248353bE43b351e606F18BE625Fef3F07DE57eF6",
  usdtAddress: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  usdtDecimals: 6,
  tokenDecimal: 8,
  refPercent: 10,
  referral: true,
  refWallet: "0x3EFa84Ba9128E6d97A8854Db9474B4b8f78DF7F8",
  tokenAddress: "0x8B8903AC6c4a0afbdcE130E17728094Dc7E178a7",
  tokens: [
    {
      name: "Tether USD",
      symbol: "USDT",
      image: usdt,
      baseToken: true,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      decimals: 6,
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      image: eth,
      native: true,
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      image: usdc,
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      decimals: 6,
    },
    {
      name: "Wrapped Bitcoin",
      symbol: "wBTC",
      image: wbtc,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      decimals: 8,
    },
    {
      name: "Chain Link",
      symbol: "LINK",
      image: link,
      address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      decimals: 18,
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      image: arb,
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      decimals: 18,
    },
  ],
  buyToken: {
    name: "EWX",
    symbol: "EWX",
    image: tokenImage,
  },
  colors: {
    first: "black",
    second: "#1d3e7c",
    header: "black",
    backgroundColor: "#F5F5F5",
    footerBgColor: "#79A12F",
  },
};

export default info;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Bread from "./components/Bread";
import NormalInput from "./components/NormalInput";
import constants from "./constants";
import info from "./info";

function PasswordReset() {
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // URL üzerinden gelen token
  const token = searchParams.get("token");
  useEffect(() => {
    console.log(token);
  }, [token]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        constants.backend_url + "/auth/password/reset",
        { token: token, password: newPassword }
      );

      if (response.status == 204) {
        toast.success("Your password changed");
        navigate("/login");
      } else {
        toast.error("Error appeared");
      }
    } catch (error) {
      console.error("Error appeared", error);
      toast.error("Error appeared");
    }
  };

  return (
    <>
      {" "}
      <Bread title={"PASSWORD RESET"} />
      <div className="container mt-5 mb-5 ">
        {" "}
        <div className="row mt-2">
          <div className="col-3">
            <hr
              className="lined"
              style={{
                float: "left",
                width: "100%",
                borderColor: info.colors.second,
              }}
            />
          </div>
          <div className="col-6  text-center">
            <h3>New Password</h3>
          </div>
          <div className="col-3">
            <hr
              className="lined"
              style={{
                float: "right",
                width: "100%",
                borderColor: info.colors.second,
              }}
            />
          </div>
        </div>
        <Card
          style={{
            border: "none",
          }}
        >
          <Card.Body>
            {" "}
            <form onSubmit={handlePasswordReset}>
              <div className="row mt-3">
                <div className="col-12">
                  <p className="text-small" style={{ fontWeight: "bold" }}>
                    New Password
                  </p>
                  <NormalInput
                    type="password"
                    value={newPassword}
                    setValue={setNewPassword}
                    error={message}
                    placeholder={"New Password"}
                  />
                </div>

                <div
                  className="col-12 mt-3"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <button className="button-custom" type="submit">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default PasswordReset;

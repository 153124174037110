import axios from "axios";
import React, { useEffect, useState } from "react";
import info from "../info";
import Loading from "./Loading";

function Footer({ content }) {
  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: info.colors.footerBgColor,
        }}
      >
        <div className="container">
          <div className="row py-5">
            <div className="col-md-4  mt-4">
              <img src="logo-footer.svg" width={100} alt="" />{" "}
              <p className="mt-3" style={{ color: "white", fontWeight: 600 }}>
                UAB "EcoWatt Digital"
                <br />
                Vilnus, Zalgirio g. 88-101, LT 09303
                <br />
                Registered EU VASP license
              </p>
              <div style={{ display: "flex" }}>
                {content.socialLinks.map((e, index) => (
                  <a
                    href={e.link}
                    target={"_blank"}
                    style={{ marginRight: "1rem" }}
                    key={index}
                  >
                    <i
                      className={e.icon}
                      style={{ color: "white", fontSize: "25px" }}
                    ></i>
                  </a>
                ))}
              </div>
            </div>
            <div className="col-md-4  mt-4">
              <h4 style={{ fontWeight: 700, color: "white" }}>Quick Links</h4>
              <div className="row mt-4" style={{ color: "white" }}>
                <div className="col-6 ">
                  {content.footerLinks1.map((e, index) => (
                    <h5
                      className="footer-link"
                      onClick={() => window.open(e.link, "_blank")}
                      key={index}
                    >
                      {e.title}
                    </h5>
                  ))}
                </div>
                <div className="col-6">
                  {content.footerLinks2.map((e, index) => (
                    <h5
                      className="footer-link"
                      onClick={() => window.open(e.link, "_blank")}
                      key={index}
                    >
                      {e.title}
                    </h5>
                  ))}{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4   mt-4">
              <h4 style={{ fontWeight: 700, color: "white" }}>Awards</h4>
              <div style={{ display: "flex" }} className="mt-4">
                <img
                  src="./award-img-1.png"
                  alt=""
                  style={{ marginRight: "1rem" }}
                />
                <img src="./award-img-2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#1D3E7C",
          width: "100%",
          fontWeight: "lighter",
          color: "white",
        }}
        className="text-center py-2"
      >
        Copyright © 2024 EcoWatt. All rights reserved.
      </div>
    </>
  );
}

export default Footer;

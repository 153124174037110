import React, { useEffect, useState } from "react";
import Input from "./components/Input";
import info from "./info.js";
import Currency from "./components/Currency";
import { Card } from "react-bootstrap";
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { ConnectKitButton } from "connectkit";
import {
  addDecimal,
  getAllowance,
  getHardcap,
  getRate,
  getRefCount,
  getRefPercent,
  getRefsBougth,
  getTokenAmount,
  getTokenBalance,
  getTokensSold,
  getUsersBought,
  getWeiRaised,
  removeDecimal,
} from "./hooks/wagmiHooks";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import { erc20Abi } from "viem";
import { config } from "./App";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { presaleAbi } from "./abi/presaleAbi";
import useWindowHeight from "./useWindowHeight";
import axios from "axios";
import Loading from "./components/Loading";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Bread from "./components/Bread";
import { useGlobalState } from "./hooks/useGlobalState";
import { useLocation, useNavigate } from "react-router-dom";
import constants from "./constants";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { useAxiosConfig } from "./hooks/useAxiosConfig.js";


const shortenAddress = (address) => {
  if (!address) return "";
  const start = address.slice(0, 6); // İlk 6 karakter
  const end = address.slice(-6); // Son 6 karakter
  return `${start}.....${end}`; // Ortada 5 nokta
};

const TokenDetails = () => {
  const details = [
    { title: "Token", content: "EcoWatt Token (EWX)" },
    { title: "Pre-Sale Price", content: "0.20 USDT" },
    { title: "Supply", content: "1,000,000,000" },
    { title: "Blockchain", content: "Arbitrum" },
    {
      title: "Utility",
      content:
        "Combatting climate change through renewable energy power plants, large-scale reforestation, and fostering global climate awareness.",
    },
    {
      title: "Features",
      content:
        "Climate Project Funding Dashboard, Staking, Carbon Credits, NFTs.",
    },
    { title: "Accepted Cryptos", content: "USDT, USDC, ETH, wBTC, LINK, ARB " },
  ];

  return (
    <div>
      {details.map((item, index) => (
        <p key={index}>
          <strong>{item.title}: </strong>
          <span>{item.content}</span>
        </p>
      ))}
    </div>
  );
};

function Presale() {
  const [barwidth, setBarwidth] = useState(0);
  const [chosenPayment, setchosenPayment] = useState(info.tokens[0]);
  const [firstinput, setfirstinput] = useState("");
  const [secondinput, setsecondinput] = useState(0);
  const [totalRaised, settotalRaised] = useState(0);
  const { address, isConnected } = useAccount();
  const [usersBuy, setusersBuy] = useState(0);
  const [approveNeeded, setApproveNeeded] = useState(true);
  const [usdtPrice, setusdtPrice] = useState(0);
  const [tokensSold, settokensSold] = useState(0);
  const [hardcap, sethardcap] = useState(0);
  const [refsBought, setrefsBought] = useState(null);
  const [refsCount, setrefsCount] = useState(null);
  const height = useWindowHeight();
  const { globalState, setGlobalState } = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [userBalance, setUserBalance] = useState(null);
  const [user, setuser] = useState();
  const axiosInstance = useAxiosConfig();
  

  const { data: hash, writeContract, error: writeError } = useWriteContract();

  const [content, setcontent] = useState(null);

  useEffect(() => {

    const getUser = async ()=>{
      try {
        const response = await axiosInstance.get("/users/me");
        console.log("response =>", response.data); 
      } catch (error) {
        console.error("Kullanıcı bilgisi alınırken hata:", error);
    }}

    if (!globalState.authToken) {
      navigate("/login");
    }

    getUser();
    
  }, [globalState, location]);

  useEffect(() => {
    const fetchConstants = async () => {
      try {
        const response = await axios.get(
          `${constants.frontend_url}/custom.json`
        );
        // `eval` kullanarak verileri window üzerinden alıyoruz.

        setcontent(response.data);
      } catch (error) {
        console.error("Error loading constants:", error);
      }
    };

    fetchConstants();
  }, []);

  useEffect(() => {
    if (writeError) {
      console.log(writeError);
      toast.error("Some Error Happened");
    }
  }, [writeError]);

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    const getApproveInfo = async () => {
      if (address && isConnected && firstinput && !chosenPayment.native) {
        const allowance = await getAllowance(address, chosenPayment.address);
        if (allowance >= Number(firstinput)) {
          setApproveNeeded(false);
        } else {
          setApproveNeeded(true);
        }
      } else if (chosenPayment.native) {
        setApproveNeeded(false);
      }
    };
    getApproveInfo();
  }, [chosenPayment, firstinput, isConfirmed]);

  useEffect(() => {
    async function getWeiRaised1() {
      const rate = await getWeiRaised();
      settotalRaised(rate);
      const getTokens = await getTokenAmount(
        info.usdtAddress,
        addDecimal(1, info.usdtDecimals)
      );
      const usdt = 1 / Number(getTokens).toFixed(0);
      setusdtPrice(usdt.toFixed(4));
      const hardc = await getHardcap();
      const tokensSold = await getTokensSold();
      sethardcap(hardc);
      settokensSold(tokensSold);
      setBarwidth((Number(tokensSold) / Number(hardc)) * 100);
    }
    getWeiRaised1();
  }, [isConfirmed]);

  useEffect(() => {
    const getuserinfo = async () => {
      if (address && isConnected) {
        const userbuyamount = await getUsersBought(address);
        console.log(userbuyamount, "usersBuy");
        setusersBuy(userbuyamount);
        const refsCount = await getRefCount(address);
        const refsBought = await getRefsBougth(address);
        setrefsCount(refsCount);
        setrefsBought(refsBought);
        const userB = await getTokenBalance(address, info.tokenAddress);
        setUserBalance(removeDecimal(userB, info.tokenDecimal, 2));
        console.log(userB);
      }
    };
    getuserinfo();
  }, [address, isConnected, isConfirmed]);

  useEffect(() => {
    const calculateTokens = async () => {
      if (!firstinput || firstinput == 0) {
        setsecondinput(0);
      } else {
        const getTokens = await getTokenAmount(
          chosenPayment.address,
          addDecimal(firstinput, chosenPayment.decimals, 0)
        );
        setsecondinput(getTokens);
      }
    };
    calculateTokens();
  }, [firstinput, chosenPayment]);

  const approveTx = async () => {
    try {
      writeContract({
        abi: erc20Abi,
        address: chosenPayment.address,
        functionName: "approve",
        args: [
          info.contractAddress,
          addDecimal(firstinput, chosenPayment.decimals),
        ],
      });
    } catch (error) {
      toast.error("Error: Transaction couldn't sent to the blockchain");
    }
  };

  const processTx = async () => {
    if (approveNeeded) {
      approveTx();
    } else {
      if (chosenPayment.native) {
        writeContract({
          abi: presaleAbi,
          address: info.contractAddress,
          functionName: "buyWithBnb",
          args: [addDecimal(firstinput, 18), globalState.referralAddress],
          value: addDecimal(firstinput, 18),
        });
      } else {
        writeContract({
          abi: presaleAbi,
          address: info.contractAddress,
          functionName: "buyTokens",
          args: [
            addDecimal(firstinput, chosenPayment.decimals),
            chosenPayment.address,
            globalState.referralAddress,
          ],
        });
      }
    }
  };

  if (!content || !globalState || !globalState.user) return <Loading />;

  return (
    <>
      <Bread title={"EWX PRESALE"} />
      <div className="container mt-5 mb-5 position-relative">
        <div className="row-col-md-12 mt-4 mb-4">
          <Card
            style={{
              border: "none",
              height: "100%",
              padding: "10px",
              paddingBottom: "20px",
            }}
          >
            <h3
              className="text-center mt-5 mb-3"
              style={{ fontWeight: "bold" }}
            >
              ACCOUNT
            </h3>
            <div className="row">

              <div className="col-md-6">
                <h4 className="text-center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Email
                </h4>

                <h4 className=" text-center">{globalState?.user.email}</h4>
              </div>
              
              {isConnected && address && (
                <div className="col-md-6">
                  <h4 className="text-center" style={{ fontWeight: "bold" }}>
                    {" "}
                    Wallet Balance{" "}
                  </h4>

                  <h4 className=" text-center">
                    {userBalance ? Number(userBalance).toLocaleString() : 0} EWX
                  </h4>
                </div>
              )}
            </div>
          </Card>
        </div>
        <div className="row pb-5  align-items-stretch">
          {/* Sol Sütun */}
          <div className="col-md-6 mt-3 mb-3 d-flex flex-column px-3">
            <div
              className="row flex-grow-1"
              style={{
                border: "none",
                backgroundColor: "white",
                color: info.colors.first,
                borderRadius: "15px",
                padding: "20px",
              }}
            >
              <Card style={{ border: "none", height: "100%" }}>
                <h5
                  className="text-center mt-5 mb-3"
                  style={{ fontWeight: "bold" }}
                >
                  EWX PRESALE
                </h5>
                <div className="row">
                  <p>
                    Invest & earn while combating climate change with the
                    EcoWatt Token (EWX).
                  </p>{" "}
                  <p>
                    EWX is backed by renewable energy power plants,
                    reforestation and energy efficiency projects.
                  </p>{" "}
                  <p>
                    Special Project: Free solar electricity to 730 million
                    people without electricity access.
                  </p>
                </div>
                <h5
                  className="text-center mt-5 mb-3"
                  style={{ fontWeight: "bold" }}
                >
                  EWX Token Details
                </h5>
                <div className="row">
                  <TokenDetails />
                </div>
              </Card>
            </div>
          </div>

          {/* Sağ Sütun */}
          <div className="col-md-6 mb-3 mt-3 px-3 d-flex flex-column justify-content-center align-items-center">
            <div
              className="row flex-grow-1"
              style={{
                border: "none",
                backgroundColor: "white",
                color: info.colors.first,
                borderRadius: "15px",
                padding: "20px",
              }}
            >
              <Card
                style={{
                  border: "none",
                }}
              >
                <Card.Body>
                  {/* <h5 className="text-center">Adoption Rate</h5>
                  <ProgressBar
                    striped
                    animated={true}
                    variant="warning"
                    now={Number(barwidth)}
                  />
                  {/* <h5 className="text-small mt-2 text-center">
                    <span className="bold-text"> USDT RAISED:</span>{" "}
                    {"$" + totalRaised}
                  </h5> */}
                  {/* <h5 className="text-small mt-2 text-center">
                    <span className="bold-text">
                      {" "}
                      Token Distribution Progress:
                    </span>{" "}
                    {Number(tokensSold).toFixed(0) + "/" + hardcap}
                  </h5> */}
                  <h5 className="text-small text-center">
                    {/*  <>
                        <span className="bold-text">
                          YOUR PURCHASED {info.buyToken.symbol} =
                        </span>{" "}
                        <>{!isConnected ? "0" : usersBuy}</>
                      </> */}
                  </h5>
                  <div className="row mt-2">
                    <div className="col-3">
                      <hr
                        className="lined"
                        style={{
                          float: "left",
                          width: "100%",
                          borderColor: info.colors.header,
                        }}
                      />
                    </div>
                    <div className="col-6  text-center">
                      <h5
                        className="text-small mt-2"
                        style={{ fontSize: "18px" }}
                      >
                        {" "}
                        1 {info.buyToken.symbol} = ${usdtPrice}
                      </h5>
                    </div>
                    <div className="col-3">
                      <hr
                        className="lined"
                        style={{
                          float: "right",
                          width: "100%",
                          borderColor: info.colors.second,
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2 mb-3 p-0">
                    <h5 className="text-center">Pay With</h5>
                    {info.tokens.map((e) => (
                      <div
                        className="col-4 m-0 p-1"
                        onClick={() => setchosenPayment(e)}
                        key={e.symbol}
                      >
                        <Currency token={e} chosenPayment={chosenPayment} />
                      </div>
                    ))}
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <p className="text-small" style={{ fontWeight: "bold" }}>
                        Amount in {chosenPayment && chosenPayment.symbol}
                      </p>
                      <Input
                        token={chosenPayment}
                        value={firstinput}
                        setValue={setfirstinput}
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <p className="text-small" style={{ fontWeight: "bold" }}>
                        Receive {info.buyToken.symbol}{" "}
                      </p>
                      <Input
                        token={info.buyToken}
                        disabled={true}
                        value={secondinput}
                        setValue={setsecondinput}
                      />
                    </div>
                    <div
                      className="col-12 mt-3"
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {!isConnected && (
                        <ConnectKitButton.Custom>
                          {({ show, hide, address, ensName, chain }) => {
                            return (
                              <button className="button-custom" onClick={show}>
                                CONNECT WALLET
                              </button>
                            );
                          }}
                        </ConnectKitButton.Custom>
                      )}
                      {address && isConnected && !isConfirming && (
                        <button className="button-custom" onClick={processTx}>
                          {approveNeeded
                            ? "APPROVE"
                            : "BUY " + info.buyToken.symbol}
                        </button>
                      )}
                      {isConfirming && (
                        <button className="button-custom">
                          <Spinner />
                        </button>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <p className="text-center">
                        Get your 10% referral commission
                      </p>

                      <div
                        className="card py-3"
                        onClick={() => {
                          if (address && isConnected) {
                            copyToClipboard(
                              constants.frontend_url + "?ref=" + address
                            );
                            toast.success("Link copied");
                          }
                        }}
                      >
                        {" "}
                        {isConnected && address && (
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <h5 className="text-center">Your Referrals</h5>
                              <h4 className="text-center">{refsCount}</h4>
                            </div>
                            <div className="col-md-6">
                              <h5 className="text-center">
                                Your Referrals Bought
                              </h5>
                              <h4 className="text-center">
                                {refsBought + " " + info.buyToken.symbol}
                              </h4>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className="text-center"
                          >
                            {address && isConnected && (
                              <>
                                {" "}
                                <i
                                  className="fa fa-copy"
                                  style={{
                                    color: info.colors.second,
                                    marginRight: "5px",
                                    fontSize: "20px",
                                  }}
                                ></i>
                                {constants.frontend_url +
                                  "?ref=" +
                                  shortenAddress(address)}
                              </>
                            )}
                            {(!address || !isConnected) && (
                              <>Connect wallet to copy referral link</>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Presale;

import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const GlobalStateContext = createContext();

// 2. Global state'i sağlayacak bir provider oluşturun
export const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    referralAddress: "",
    authToken: "",
  });

  const logout = () => {
    // Token ve kullanıcı bilgilerini temizle
    setGlobalState({ authToken: null, user: null });
    // LocalStorage'dan token'ı temizle
    localStorage.removeItem("authToken");
  };

  return (
    <GlobalStateContext.Provider
      value={{ globalState, setGlobalState, logout }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

// 3. useContext kullanarak global state'e erişmek için bir özel bir hook oluşturun
export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("Global state is on wrong place");
  }
  return context;
};

import { WagmiProvider, createConfig, http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import info from "./info";
import "bootstrap/dist/css/bootstrap.min.css";
import { arbitrum } from "wagmi/chains";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Presale from "./Presale";
import Login from "./Login";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "./components/Loading";
import { GlobalStateProvider, useGlobalState } from "./hooks/useGlobalState";
import constants from "./constants";
import { useNavigate } from "react-router-dom";
import Kyc from "./Kyc";
import PasswordReset from "./PasswordReset";

export const config = createConfig({
  chains: [arbitrum],
  transports: {
    [arbitrum.id]: http(),
  },
});

// Web3Provider Component
const Web3Provider = ({ children }) => {
  const queryClient = new QueryClient();
  const conf = createConfig(
    getDefaultConfig({
      alchemyId: "eNXEZKsx5CzGyhki4Oa41TDsk_-6O2RF", // or infuraId
      walletConnectProjectId: "b6d1cc78bdf15b2bb2d8a8e186cf9ba2",
      appName: "Ecowatt Presale",
      chains: [arbitrum],
    })
  );

  return (
    <WagmiProvider config={conf}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider>
          <div
            style={{
              backgroundColor: info.colors.backgroundColor,
              color: info.colors.first,
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {children}
          </div>
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

// AppContent Component
const AppContent = ({ children }) => {
  const { globalState, setGlobalState } = useGlobalState();
  const [content, setContent] = useState(null);
  const navigate = useNavigate();

  const getRef = () => {
    const url = new URL(window.location.href);
    const ref =
      url.searchParams.get("ref") ||
      localStorage.getItem("ref") ||
      info.refWallet;
    if (isValidAddress(ref)) {
      localStorage.setItem("ref", ref);
      setGlobalState((prev) => ({ ...prev, referralAddress: ref }));
    } else {
      localStorage.setItem("ref", info.refWallet);
      setGlobalState((prev) => ({ ...prev, referralAddress: info.refWallet }));
    }
  };

  const isValidAddress = (address) => /^0x[a-fA-F0-9]{40}$/.test(address);

  useEffect(() => {
    getRef();
    const fetchConstants = async () => {
      try {
        const response = await axios.get(
          `${constants.frontend_url}/custom.json`
        );
        setContent(response.data);
      } catch (error) {
        console.error("Error loading constants:", error);
      }
    };
    fetchConstants();
  }, []);

  useEffect(() => {
    const checkTokenAndFetchProfile = async () => {
      const token = localStorage.getItem("authToken");
      if (token) {
        try {
          // Token'ı global state'e kaydet
          setGlobalState((prev) => ({ ...prev, authToken: token }));

          // Kullanıcı profilini çek
          const response = await axios.get(
            constants.backend_url + "/items/users?fields=*.*",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Kullanıcı bilgilerini global state'e kaydet
          setGlobalState((prev) => ({
            ...prev,
            user: response.data.data[0],
          }));

          // Geçerli bir token varsa kullanıcıyı yönlendir
          navigate("/");
        } catch (error) {
          console.error("Token geçersiz veya kullanıcı doğrulanamadı:", error);
          // Token geçersizse localStorage'ı temizle
          localStorage.removeItem("authToken");
          navigate("/login");
        }
      } else {
        // Eğer token yoksa giriş sayfasına yönlendir
        if (window.location.pathname != "/reset-password") {
          navigate("/login");
        }
      }
    };

    checkTokenAndFetchProfile();
  }, []);

  useEffect(() => {
    console.log(globalState);
  }, [globalState]);

  if (!content) return <Loading />;

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Web3Provider>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Header content={content} />
          </div>
          <div>{children}</div>
          <div>
            {" "}
            <Footer content={content} />
          </div>
        </div>
      </Web3Provider>
    </>
  );
};

// Main App Component
const App = () => {
  return (
    <GlobalStateProvider>
      <Router>
        <AppContent>
          <Routes>
            <Route path="/" element={<Presale />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<PasswordReset />} />
          </Routes>
        </AppContent>
      </Router>
    </GlobalStateProvider>
  );
};

export default App;

import axios from "axios";
import constants from "../constants";
import { useGlobalState } from "./useGlobalState";

export const useAxiosConfig = () => {
  const { globalState } = useGlobalState();

  const axiosInstance = axios.create({
    baseURL: constants.backend_url, // Directus URL'niz
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = globalState?.authToken;
      if (token) {
        
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
